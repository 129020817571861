import { z } from 'zod';

import { ConnectionProviderNameSchema } from './connection-providers';
import { ProviderIdSchema, StreamIdSchema, integerString } from './ids';
import { nonnegativeInteger } from './utils';

const StreamFilterSchema = z
  .object({
    field: z.string().min(1, 'Select property'),
    operator: z.string().min(1, 'Select condition'),
    values: z.array(z.string().min(1)),
  })
  .refine(data => data.operator === 'IS NULL' || data.values.length > 0, {
    message: 'Enter a value',
    path: ['values'],
  });
export type StreamFilter = z.infer<typeof StreamFilterSchema>;

export const StreamConnectionSchema = z.object({
  id: integerString,
  name: ConnectionProviderNameSchema,
  displayName: z.string().min(1),
  providerId: ProviderIdSchema,
  filters: StreamFilterSchema.array(),
});
export type StreamConnection = z.infer<typeof StreamConnectionSchema>;

export const StreamStatusSchema = z.enum(['PROCESSING', 'SUCCESS', 'LOW_VOLUME', 'NO_TICKETS']);
export type StreamStatus = z.infer<typeof StreamStatusSchema>;

export const StreamSchema = z.object({
  id: StreamIdSchema,
  name: z.string().min(1, 'Give your ticket group a name'),
  connections: StreamConnectionSchema.array(),
  isOnline: z.boolean(),
  targetResponseTime: z
    .number()
    .int('Target response time must be a whole number')
    .nonnegative('Target response time must be a whole number greater than 0'),
  averageHandleTime: z
    .number()
    .int('Average handle time must be a whole number')
    .nonnegative('Average handle time must be a whole number greater than 0'),
  adherenceAverageHandleTime: nonnegativeInteger.nullable().optional(),
  serviceLevel: z
    .number()
    .int('Service level percent time must be a whole number')
    .min(0, 'Service level percent must be a number between 0 and 99')
    .max(99, 'Service level percent must be a number between 0 and 99'),
  color: z.string().min(1),
  status: StreamStatusSchema,
  concurrency: z.number().min(1, 'Concurrency must be a number greater than or equal to 1'),
  shrinkage: z
    .number()
    .min(0, 'Shrinkage percent must be a number between 0 and 100')
    .max(1, 'Shrinkage percent must be a number between 0 and 100'),
  weeksOfTrainingData: nonnegativeInteger.nullish(),
});

export const GetStreamsRespSchema = StreamSchema.array();
export type GetStreamsResp = z.infer<typeof GetStreamsRespSchema>;

export type Stream = z.infer<typeof StreamSchema>;

export const NewStreamConnectionSchema = z.object({
  id: z.number(),
  filters: z.array(StreamFilterSchema),
});

export type NewStreamConnection = z.infer<typeof NewStreamConnectionSchema>;

export const NewStreamSchema = StreamSchema.omit({
  id: true,
  status: true,
  connections: true,
}).merge(
  z.object({
    connections: NewStreamConnectionSchema.array().min(1),
  }),
);
export type NewStream = z.infer<typeof NewStreamSchema>;

export const ChartDataSchema = z.array(z.object({ date: z.string(), value: z.number() }));
export type ChartData = z.infer<typeof ChartDataSchema>;

export const StreamPreviewRespSchema = z.object({
  historicData: z.boolean(),
  chartData: ChartDataSchema,
});
export type StreamPreviewResp = z.infer<typeof StreamPreviewRespSchema>;
