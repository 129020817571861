import { queryOptions, useQuery } from '@tanstack/react-query';
import { OAuthAuthorizeUrlSchema } from 'shared-types';

import { useRequest } from './api';

export const useLogin = () => {
  const request = useRequest();

  const options = queryOptions({
    queryKey: ['login', 'authorize'],
    queryFn: () =>
      request(`/auth/authorize`, OAuthAuthorizeUrlSchema, {
        method: 'GET',
        useExactPathProvided: true,
      }),
  });

  return useQuery(options);
};
