import { z } from 'zod';

import { UserLoginSchema } from './auth0';
import { DialpadUserIdSchema, ManagerIdSchema, SurferIdSchema, TeamIdSchema } from './ids';

export const TeamSchedulePermissionSchema = z.object({
  teamId: TeamIdSchema,
  teamName: z.string(),
  surfers: z.array(SurferIdSchema),
});
export type TeamSchedulePermission = z.infer<typeof TeamSchedulePermissionSchema>;

export const ManagerSchema = z.object({
  firstName: z.string().min(1, { message: 'Enter a first name' }),
  lastName: z.string().min(1, { message: 'Enter a last name' }),
  email: z.string().email({ message: 'Enter a valid email address' }),
  teamSchedulePermissions: TeamSchedulePermissionSchema.array().optional(),
  dialpadUserId: DialpadUserIdSchema.optional(),
});

export type Manager = z.infer<typeof ManagerSchema>;

export const PersistedManagerSchema = ManagerSchema.extend({
  id: ManagerIdSchema,
});

export type PersistedManager = z.infer<typeof PersistedManagerSchema>;

export const InviteManagerReqSchema = z.object({
  managerId: ManagerIdSchema,
});

export const ManagerReqSchema = ManagerSchema.extend({
  editPermissionTeamIds: TeamIdSchema.array().optional(),
}).omit({ teamSchedulePermissions: true });
export type ManagerReq = z.infer<typeof ManagerReqSchema>;

export const CreateManagerReqSchema = ManagerSchema;

export const GetManagersRespSchema = PersistedManagerSchema.array();
export type GetManagersResp = z.infer<typeof GetManagersRespSchema>;

export const GetManagerLoginsRespSchema = UserLoginSchema.array();
export type GetManagerLoginsResp = z.infer<typeof GetManagerLoginsRespSchema>;
